import React from "react";

// Components
import SecondaryLanding from "../../layouts/secondary-landing";
import SEO from "../../components/seo/seo";
import Breadcrumb from "../../components/breadcrumb/breadcrumb";
import FaqAccordion from "../../components/faq/faq-accordion";
import ContactInfo from "../../components/contact-info/contact-info";
import BestBanksDefault from "../../components/best-banks/best-banks-default";

const RewardsCheckingFAQ = () => {
  const seoData = {
    title: "Rewards Checking Benefits & Services - FAQ",
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        content: "Rewards Checking Benefits & Services - FAQ"
      },
      {
        name: "description",
        content:
          "Compare WaFd Bank Rewards Checking benefits. Access rewards & services like health discounts, roadside assistance & fuel savings. Call 800-324-9375 for details."
      },
      {
        property: "og:title",
        content: "Rewards Checking Benefits & Services - FAQ"
      },
      {
        property: "og:description",
        content:
          "Compare WaFd Bank Rewards Checking benefits. Access rewards & services like health discounts, roadside assistance & fuel savings. Call 800-324-9375 for details."
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/personal-banking/rewards-checking-faq"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      }
    ]
  };

  const breadcrumbData = [
    {
      id: 1,
      url: "/personal-banking",
      title: "Personal Banking"
    },
    {
      id: 2,
      active: true,
      title: "Rewards Checking FAQ"
    }
  ];

  const faqAccordionData = {
    id: "rewards-checking-faq-accordion",
    faqCategoryNames: ["Rewards Checking"],
    showContactBar: false,
    title: "FAQs"
  };

  return (
    <SecondaryLanding footerBorder={false}>
      <SEO {...seoData} />
      <Breadcrumb data={breadcrumbData} />
      <section className="container pb-0">
        <h1>Checking Account Rewards Frequently Asked Questions</h1>
        <h3>
          WaFd Bank is excited to extend checking account Rewards<sup>1</sup> to those with Rewards, Premium Rewards,
          Interest, or Select Checking accounts.
        </h3>
        <p className="mb-0">
          If you have any additional questions, please call us at{" "}
          <a href="tel:800-324-9375" id="c3-tel-link">
            800-324-9375
          </a>
          .
        </p>
      </section>
      <FaqAccordion {...faqAccordionData} />
      <ContactInfo />
      <BestBanksDefault />
    </SecondaryLanding>
  );
};

export default RewardsCheckingFAQ;
